import { alpha, Box, Container, Stack, Typography } from "@mui/material";
import CommonPage from "../Common/CommonPage";
import Footer from "../Footer/Footer";
import SettingsMain from "./SettingsMain";


export default function Settings() {
    let main;

    main = (
        <Box
        id="hero"
        sx={(theme) => ({
            width: '100%',
            backgroundImage:
            theme.palette.mode === 'light'
                ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
            backgroundSize: '100% 20%',
            backgroundRepeat: 'no-repeat',
        })}
        >
            <SettingsMain/>
            <Footer/>
        </Box>
    );
    return(
        <CommonPage main={main}/>
    );
}