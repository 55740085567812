import { Box, Container, Grid, Link, Stack, Typography } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { useEffect, useState } from "react";
import IFile from "../Interfaces/IFile";


export default function Camera2TestMain() {
    const [camera, setCamera] = useState<any>({});
    const [files, setFiles] = useState<IFile[]>([]);
    const apiUrl = process.env.REACT_APP_API_URL;


    const handleClick = (url: string) => {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = ''; 
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };
    
    useEffect(() => {

        const getFiles = async () => {
            fetch(`${apiUrl}/api/File/GetFilesByVersion/9c8d87f3-484c-43d6-80d3-b79707d2f245`, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
                }).then(res => res.json())
                .then(res => {
                    setFiles(res.files);
                });
        }
        getFiles();
    }, []);

    return(
        <Box>
            <Container
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            pt: { xs: 14, sm: 20 },
            pb: { xs: 3, sm: 8 },
            borderBottom: '2px solid',
            borderColor: 'divider'
            }}
        >
            <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
            <Typography
                variant="h1"
                sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignSelf: 'center',
                textAlign: 'center',
                fontSize: 'clamp(3.5rem, 10vw, 4rem)',
                }}
            >
                Camera2Test&nbsp;
                <Typography
                component="span"
                variant="h1"
                sx={{
                    fontSize: 'clamp(3rem, 10vw, 4rem)',
                    color: (theme) =>
                    theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                }}
                >
                App
                </Typography>
            </Typography>
            <Typography
                textAlign="left"
                color="text.secondary"
                sx={{ alignSelf: 'left',  fontSize: '16px', width: { xs: '100%', sm: '80%' }}}
            >
                The Camera2Test app is a tool that helps users determine the camera hardware capabilities of their Android device. This information is especially useful when setting up GCam, as different devices support different levels of camera functionality.            
            </Typography>
            <Typography
                textAlign="left"
                color="text.secondary"
                sx={{ alignSelf: 'left',  fontSize: '16px', width: { xs: '100%', sm: '80%' }}}
            >
                With Camera2Test, you can verify if your device meets the necessary requirements for advanced photography features in GCam, such as manual exposure, RAW capture, and HDR+.            </Typography>
            </Stack>
            </Container>

            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    pt: { xs: 7, sm: 10 },
                    pb: { xs: 3, sm: 8 },
                    borderBottom: '2px solid',
                    borderColor: 'divider'
                    }}>
                <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
                    <Box sx={{ width: '100%', alignSelf: 'flex-start', paddingBottom: '2%' }}>
                    <Typography textAlign="left" fontSize="18px">
                        <b>Download:</b>
                    </Typography>
                    {files.map((file) => (
                        <Typography
                            key={file.fileId}
                            textAlign="left"
                            color="text.secondary"
                            fontSize="18px"
                        >
                            <DownloadIcon />{' '}
                            <Link
                                component="button"
                                variant="body2"
                                underline="hover"
                                onClick={(e) => handleClick(file.presignedUrl)}
                                sx={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
                            >
                                {file.fileName}
                            </Link>
                        </Typography>
                    ))}
                    </Box>
                </Stack>
            </Container>

            <Container
            sx={{
                pb: { xs: 3, sm: 8 },
                borderBottom: '2px solid',
                borderColor: 'divider'
                }}>
                <Stack>
                <Grid
                    container
                    spacing={2} // Расстояние между элементами
                    sx={{
                        mt: 4, // Отступ сверху
                    }}>
                    <Grid item xs={12} sm={6} md={4}>
                        {/* xs=12: занимает всю ширину на телефонах */}
                        {/* sm=6: занимает половину ширины на планшетах */}
                        {/* md=4: занимает треть ширины на больших экранах */}
                        <Box
                        component="img"
                        src={`${process.env.PUBLIC_URL}/camera2test-1pic.png`}
                        sx={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: 2,
                            boxShadow: 3,
                            objectFit: 'cover',
                        }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box
                        component="img"
                        src={`${process.env.PUBLIC_URL}/camera2test-2pic.png`}
                        sx={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: 2,
                            boxShadow: 3,
                            objectFit: 'cover',
                        }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box
                        component="img"
                        src={`${process.env.PUBLIC_URL}/camera2test-3pic.png`}
                        sx={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: 2,
                            boxShadow: 3,
                            objectFit: 'cover',
                        }}
                        />
                    </Grid>
                </Grid>
                </Stack>
            </Container>

            <Container 
            sx={{
                pt: { xs: 7, sm: 10 },
                pb: { xs: 3, sm: 8 }
                }}>
                <Stack>
                <Box sx={{ width: '100%', alignSelf: 'flex-start', paddingBottom: '2%' }}>
                    <Typography textAlign="left" fontSize="18px" sx={{pb: { xs: 3, sm: 8 }}}>
                    To use Google Camera, support for RAW format and Camera2 API is required
                    </Typography>
                    <Grid
                    container
                    spacing={2} // Расстояние между элементами
                    sx={{
                        mt: 4, // Отступ сверху
                    }}>
                    <Grid item xs={12} sm={6} md={4}>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                    <Box
                        component="img"
                        src={`${process.env.PUBLIC_URL}/camera2test-raw.jpg`}
                        sx={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: 2,
                            boxShadow: 3,
                            objectFit: 'cover',
                        }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        
                    </Grid>
                </Grid>
                    </Box>
                </Stack>
            </Container>
        </Box>
    );
}