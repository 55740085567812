import './App.css';
import { Link, Route, Routes } from 'react-router-dom';
import { Box, Container, Grid, Typography, Button } from '@mui/material';
import LandingPage from './Components/LandingPage';
import VersionsPage from './Components/Versions/VersionsPage';
import VersionPage from './Components/Version/VersionPage';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import ParametersGuide from './Components/Parameters Guide/ParametersGuide';
import ParametersDictionary from './Components/Parameters Guide/ParametersDictionary';
import Setups from './Components/Setups/Setups';
import FAQ from './Components/FAQ/FAQ';
import Installation from './Components/Installation/Installation';
import Camera2Test from './Components/Camera2Test/Camera2Test';
import Settings from './Components/Settings/Settings';

function App() {
  
  return (
    <div className="App">
         <Routes>
            <Route path="/" element={<LandingPage/>}></Route>
            <Route path="versions-bsg" element={<VersionsPage/>}></Route>
            <Route path="versions-bsg/:id" element={<VersionPage />}></Route>
            <Route path="about-bsg" element={<About />}></Route>
            <Route path="contact-bsg" element={<Contact />}></Route>
            <Route path="parameters-bsg" element={<ParametersGuide />}></Route>
            <Route path="parameters-dictionary-bsg" element={<ParametersDictionary />}></Route>
            <Route path="setups-bsg" element={<Setups />}></Route>
            <Route path="installation-bsg" element={<Installation />}></Route>
            <Route path="settings-bsg" element={<Settings />}></Route>
            <Route path="camera2test-bsg" element={<Camera2Test />}></Route>
            <Route path="faq-bsg" element={<FAQ />}></Route>
            <Route path="privacypolicy" element={<PrivacyPolicy />}></Route>
            <Route path="*" element={<NoMatchComponent />} />
          </Routes>
    </div>
  );
}

const NoMatchComponent = () => {
  return (
    <div className='errorPage'>
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid xs={6}>
            <Typography variant="h1">
              404
            </Typography>
            <Typography variant="h6">
              The page you’re looking for doesn’t exist.
            </Typography>
            <Button variant="contained"><Link to="/" style={{color: "white", textDecoration: "none"}}>Back Home</Link></Button>
          </Grid>
          <Grid xs={6}>
            <img
              src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
              alt=""
              className='imgError'
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
    </div>
  );
}

export default App;
